<template>
  <div class="stepOut">
    <ul style="padding: 0; margin: 0">
      <li
        class="stepItem"
        :style="'width:' + stepInfo.width + 'rem;'"
        v-for="(stepItem, index) in stepInfo.list"
        :key="index"
      >
        <!-- 步骤名称  -->
        <p
          :class="
            stepInfo.step >= index + 1
              ? 'stepStatus stepStatusactive'
              : 'stepStatus'
          "
        >
          {{ stepItem.status }}
        </p>
        <!-- 模拟步骤条的节点，此处为圆圈 -->
        <div class="icon">
          {{ index + 1 }}
          <div v-if="stepInfo.step >= index + 1" class="iconactive">
            {{ index + 1 }}
          </div>
        </div>
        <!-- 模拟步骤条连接线，动态显示  -->
        <div
          :class="stepInfo.step >= index + 2 ? 'line lineActive' : 'line'"
          :style="'width:' + stepInfo.width + 'rem;'"
          v-show="index !== stepInfo.list.length - 1"
        ></div>

        <!-- 步骤时间  -->
        <p class="statusTime">{{ stepItem.statusTime }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "steps",
  props: {
    // 传递步骤参数
    stepInfo: {
      type: Object,
      default: function () {
        return {
          list: [],
          step: 0,
          width: 11,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.stepOut {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  .stepItem {
    // width: 160px;
    height: 70px;
    float: left;
    font-family: SimSun;
    font-size: 16px;
    text-align: center;
    position: relative;
    .icon {
      width: 32px;
      height: 32px;
      padding: 5px;
      background: #dcdcdc;
      border-radius: 50%;
      margin: 0 auto;
      position: relative;
      z-index: 888;
      font-size: 10px;
      color: #ffffff;
      .iconactive {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #00a3e0;
        font-size: 10px;
        color: #ffffff;
      }
    }

    .line {
      position: absolute;
      top: 34px;
      left: 50%;
      border-bottom: 8px solid #dcdcdc;
      width: 160px;
      z-index: 111;
    }
    .lineActive {
      border-bottom: 8px solid #00a3e0;
    }
    .stepStatus {
      //   line-height: 36px;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      color: #818181;
    }
    .stepStatusactive {
      margin: 0;
      color: #00a3e0;
    }
    .statusTime {
      color: #818181;
      margin-top: 16px;
			font-size: 14px;
      //   opacity: 0.5;
    }
  }
}
</style>